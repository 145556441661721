@import "/src/styles/mixins";

.view-menu-request-modal {
  .cartItem {
    width: 100%;
  }

  .quantity {
    padding: 5px 10px 0px 10px;
    border: 1px #ddd solid;
    font-weight: 700;
    background-color: #eee;
    height: 25px;
  }

  .productName {
    font-weight: 700;
  }

  .totals {
    width: 300px;
  }

  .total {
    font-weight: 700;
  }
}
