@import "/src/styles/mixins";

.imageCheckin {
  height: 150px;
  margin-top: 10px;
  margin: 10px auto;
}

.imageSignature {
  max-height: 200px;
  max-width: 200px;
  margin-top: 10px;
  margin: 10px auto 20px auto;
}
