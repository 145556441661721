@import "../../styles/mixins";

.message {
  max-width: 60% !important;
  margin-bottom: 3px;
}

.date {
  margin-bottom: 20px;
}

.message-input {
  position: "relative";
  height: "100%";
  z-index: 1;
}

.watermark {
  position: absolute;
  z-index: 0;
  display: block;
  min-height: 50%;
  min-width: 50%;
  & > p {
    text-align: center;
    color: lightgrey;
    font-size: 40px;
    transform: rotate(-25deg);
    -webkit-transform: rotate(-25deg);
  }
}
