@import "/src/styles/mixins";

.details-header {
  position: "relative";
  margin-bottom: 20px;
  .back-button {
    position: "absolute";
    left: 0;
  }
  .heading {
    margin: 0 auto;
  }
}

.details-tabs {
  margin-top: -25px;
}

.details-tabs > div {
  margin-bottom: 20px;
}

.conversation-title {
  text-align: center;
}

.has-new-messages {
  position: relative;
}
.has-new-messages::after {
  content: " ";
  background: red;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50px;
  top: 0px;
  right: 0px;
}
