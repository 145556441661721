@import "/src/styles/mixins";

.message {
  max-width: 60% !important;
  margin-bottom: 3px;
}
.photo {
  border-radius: 10px;
  margin-bottom: 5px;
}

.date {
  margin-bottom: 20px;
}
