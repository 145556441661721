@import "../../styles/mixins";
@import "../../../node_modules/react-phone-number-input/style.css";

.customPhoneInput {
  position: relative;
  .international {
    position: absolute;
    bottom: -20px;
    font-size: 11px;
  }
}

.PhoneInputInput {
  border: none;
  font-weight: 700;
  font-size: inherit;
  &:focus {
    outline: none;
  }
}
