.SortableList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  list-style: none;

  @media screen and (max-width: 968px) {
    width: fit-content;
  }
}
