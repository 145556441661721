@import "../../styles/mixins";

.phoneInputField {
  position: relative;
  .international {
    position: absolute;
    bottom: -20px;
    font-size: 11px;
  }
}

.unassign {
  font-size: 14px !important;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.buttons {
  @media only screen and (max-width: 640px) {
    height: 80px;
  }
}
