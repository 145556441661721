@import "../../styles/mixins";

.doorTables {
  width: 50%;
}

.doorTablesContainer {
  width: fit-content;
  @media screen and (max-width: 1375px) {
    min-width: 1000px;
    overflow-x: auto;
    padding-left: 200px;
  }
}
